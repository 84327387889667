<template>
  <Content>
    <template #contentTitle>{{ $t('menu.storage_label_change_create') }}</template>
    <template #contentBody>
      <StepsRow :current="pageStep" />
      <StepThree :task-id="formState.taskId" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Steps } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import StepsRow from './components/StepsRow.vue';
import StepThree from './components/StepThree.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'storage_label_change_confirm',
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    Content,
    StepsRow,
    StepThree,
  },
  setup () {
    const route = useRoute();

    const state = reactive({
      pageStep: 3,
      formState: {
        taskId: null,
      }
    });

    onMounted(() => {
      let params = route.params;
      if ('id' in params) {
        let taskId = params.id;
        state.formState.taskId = taskId;
      }
    });

    return {
      ...toRefs(state),
    }
  }
})
</script>

<style scoped></style>