<template>
  <a-spin :spinning="loading">
    <a-row :gutter="[8, 8]">
      <a-col :span="24">{{ $t('warehouse.label_task_name') }}: {{ pageInfo.taskName }}</a-col>
      <a-col :span="24">{{ $t('warehouse.tag_task_number') }}: {{ pageInfo.taskNo }}<a-button size="small" style="margin-left: 5px;" danger @click="handleCancel">{{ $t('common.cancel') }}</a-button></a-col>
    </a-row>
    <a-table
      style="overflow-x: auto;"
      class="mt-3"
      size="small"
      :columns="columns"
      :data-source="shipmentList"
      :pagination="false"
      :row-key="(record) => record.detailId"
    >
      <template #serialNumber="{ index, record }">
        {{ index + 1 }}
      </template>
      <template #boxNo="{ record }">
        {{ record.box?.boxNo || '-' }}
      </template>
      <template #selfBoxNo="{ record }">
        {{ record.box?.selfBoxNo || '-' }}
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
          :columns="innerColumns"
          :data-source="record.items"
          :pagination="false"
          :row-key="(record) => record.productRlId"
        >
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #productIdentifyImgs="{ record }">
            <a-row :gutter="8" v-if="record.productIdentifyImgs.length">
              <a-col v-for="(item, index) in record.productIdentifyImgs" :key="index">
                <CFileDisplay :fileUrl="item" />
              </a-col>
            </a-row>
            <span v-else>-</span>
          </template>
          <template #productLabelUrl="{ record }">
            <CFileDisplay :fileUrl="record.labelUrl" />
          </template>
        </a-table>
      </template>
    </a-table>
    <a-row class="mt-3" :gutter="[16, 16]" type="flex" justify="space-between">
      <a-col :md="24" :xl="12">
        <a-row :gutter="[16, 8]">
          <a-col :span="24">{{ $t('warehouse.label_service_type') }}: <span style="font-weight: bold;">{{ $t($enumLangkey('labelTaskType', pageInfo.labelTaskType)) }}</span></a-col>
          <a-col :span="24"><a-checkbox v-model:checked="agreeOnTheDifference">{{ $t('warehouse.agree_on_the_difference') }}</a-checkbox></a-col>
        </a-row>
      </a-col>
      <a-col>
        <CFee v-if="fee.totalFee" :fees="fee.fees" :totalFee="fee.totalFee" />
      </a-col>
    </a-row>
    <a-row class="mt-5 mb-3" type="flex" justify="end">
      <a-col class="mr-3">
        <a-button type="primary" :loading="confirmLoading" :disabled="!fee.totalFee" @click="handleOpenPayment()">{{ $t('warehouse.confirm_and_pay') }}</a-button>
      </a-col>
    </a-row>
  </a-spin>
  <Payment
    ref="paymentRef"
    v-model:visible="payModal.visible"
    :loading="payModal.loading"
    :currencyId="fee.totalFee?.currencyId ?? ''"
    :unpaidSymbol="fee.totalFee?.symbol ?? ''"
    :unpaidSum="fee.totalFee?.value ?? 0"
    @handlePayFee="handleConfirm"
  />
</template>

<script>
import { defineComponent, reactive, nextTick, toRefs, ref, watch } from 'vue';
import { Button, Checkbox, Col, Input, Modal, Row, Spin, Table, message } from 'ant-design-vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import CFee from '@/views/components/CFee.vue';
import Payment from '@/views/components/Payment.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import {
  getThirdStep,
  getFee,
  createThirdStep,
  cancelStorageUserLabelChange,
} from '@/api/modules/storage/labelChange.js';
import { setFormStateValue } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";
import filters from "@/filters";
export default defineComponent({
  name: 'stepThree',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    ACheckbox: Checkbox,
    AInput: Input,
    CFileDisplay,
    CFee,
    Payment,
  },
  props: {
    taskId: String,
  },
  setup (props) {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const paymentRef = ref(null);
    
    const state = reactive({
      loading: false,
      confirmLoading: false,
      formState: {
        taskId: null,
      },
      pageInfo: {
        taskName: null,
        taskNo: null,
        labelTaskType: null,
      },
      fee: {
        fees: [],
        totalFee: null,
      },
      shipmentList: [],
      agreeOnTheDifference: false,
      payModal: {
        visible: false,
        loading: false,
      }
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.se_box_number'),
        slots: {
          customRender: 'boxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('common.user_defined_box_no'),
        slots: {
          customRender: 'selfBoxNo',
        }
      },
    ];

    const innerColumns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        dataIndex: 'productIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.identifiable_product_information'),
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.recognizable_product_images'),
        slots: {
          customRender: 'productIdentifyImgs',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_labeling_document'),
        slots: {
          customRender: 'productLabelUrl',
        }
      },
      {
        dataIndex: 'newProductIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_label'),
      },
      {
        dataIndex: 'productCount',
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
    ];

    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelStorageUserLabelChange({ taskId: state.formState.taskId }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            handleBack();
          });
        },
      });
    }

    const handleOpenPayment = () => {
      if (!state.agreeOnTheDifference) {
        message.error(vueI18n.t('warehouse.please_agree_to_authorize_automatic_deduction'));
        return;
      }
      state.payModal.visible = true;
      nextTick(() => {
        paymentRef.value.handleShowPayOrder();
      })
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/storage/labelchange');
    }

    const handleConfirm = () => {
      state.payModal.loading = true;
      createThirdStep({ taskId: state.formState.taskId,payFee:filters.amountToFixed2(state.fee.totalFee?.value) })
        .then(() => {
          message.success(vueI18n.t('common.succeed'));
          handleBack();
        })
        .catch(() => {})
        .finally(() => {
          state.payModal.loading = false;
        })
    }

    const getThirdStepDetail = async () => {
      try {
        state.loading = true;
        let { result: pageInfo } = await getThirdStep({ taskId: state.formState.taskId });
        setFormStateValue(state.pageInfo, pageInfo);
        state.shipmentList = pageInfo.items;

        let { result: fee } = await getFee({ taskId: state.formState.taskId });
        state.fee = fee;
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    watch(() => props.taskId, (value) => {
      if (value) {
        state.formState.taskId = value;
        getThirdStepDetail();
      }
    }, { immediate: true });

    return {
      ...toRefs(state),
      paymentRef,
      columns,
      innerColumns,
      handleCancel,
      handleOpenPayment,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>